import { createAiProcess } from "@shiftx/ai"
import { analytics } from "@shiftx/analytics"
import { Events2 } from "@shiftx/analytics/events"
import { Chat, Flag, Image, Minus, Plus, Stars, Text } from "@shiftx/icons"
import { ProcessScopeMode } from "@shiftx/process"
import { baseFormat, normalizeReadable } from "@shiftx/readable"
import { css } from "@shiftx/styles"
import dynamic from "next/dynamic"
import { useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import {
    ButtonType,
    Input,
    Link,
    LoadingButton,
} from "../../../combonentLibary"
import { Button } from "../../components/Button"

import { ProcessOptions } from "@shiftx/process/src/components/Process/Process"
import RouterLink from "next/link"
import { useSearchParams } from "next/navigation"
import { Title, Title4 } from "../../components/Text"
import { lessThan } from "../../utils/breakpoints"
import { CommentGUI, IssueUSP } from "../HomePage/Collaborative"
import { ImproveUSP } from "../ProcessMapping/ProcessMappingImprove"
import { TripletUSP } from "../ProcessMapping/ProcessMappingModelling"
import { ImageToFlowInput } from "./components/ImageToFlowInput"
import { TextToFlowInput } from "./components/TextToFlowInput"

const ExternalProcess = dynamic(
    () => import("@shiftx/process").then(mod => mod.ExternalProcess),
    { ssr: false }
)

const UseCaseItem = ({ icon, label, value, useCase, setUseCase }) => {
    return (
        <div
            onClick={() => {
                setUseCase(value)
            }}
            css={css`
                cursor: pointer;
                padding: 24px;
                border-radius: 6px;
                display: grid;
                gap: 8px;
                place-items: center;
                transition: background-color 0.2s;
                :hover {
                    background-color: var(--fg-100);
                }

                ${useCase === value &&
                css`
                    color: #fff;
                    background-color: var(--fg-100);
                `}
            `}
        >
            {icon}
            {label}
        </div>
    )
}
export const AiPage = ({ improve, mapping }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [controlledState, setControlledState] = useState(null)

    const params = useSearchParams()
    const prompt = params.get("prompt")

    const [useCase, setUseCase] = useState("textToFlow")

    const [processContainerRef, setProcessContainerRef] =
        useState<HTMLDivElement>(null)

    const {
        register,
        handleSubmit,
        control,
        setError,
        clearErrors,
        formState: { errors, isSubmitted },
    } = useForm({ defaultValues: { useCase: prompt } })

    const [formRef, setFormRef] = useState(null)

    useEffect(() => {
        if (prompt && formRef) {
            formRef.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
            )
        }
    }, [prompt, formRef])

    useEffect(() => {
        if (processContainerRef) {
            const element = processContainerRef
            const elementRect = element.getBoundingClientRect()
            const absoluteElementTop = elementRect.top + window.pageYOffset
            const middle =
                absoluteElementTop -
                window.innerHeight / 2 +
                window.innerHeight / 4
            window.scrollTo(0, middle)
        }
    }, [processContainerRef, isSubmitted])

    const errorMessage = errors?.useCase?.message || errors?.file?.message

    useEffect(() => {
        if (useCase) {
            clearErrors()
        }
    }, [useCase])

    const [id, setId] = useState(null)

    return (
        <div>
            <Title
                css={css`
                    font-family: "Albert Sans";
                    font-style: normal;
                    font-weight: 300;
                    text-align: left;
                    text-wrap: pretty;
                    width: 80%;
                    ${lessThan(1)`
                        width: 100%;
                        font-size:36px;
                        line-height:48px;
                    `}
                `}
            >
                Improve your processes with{" "}
                <span
                    id="content1"
                    css={css`
                        background: linear-gradient(90deg, #77f 0%, #77f 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: 600;
                        white-space: wrap;
                    `}
                >
                    Artificial Intelligence
                </span>
            </Title>

            <div
                css={css`
                    margin-top: 24px;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 36px;
                    width: 70%;
                    ${lessThan(1)`
                     font-size: 16px;
                     line-height: 28px;
                        width: 100%;
                    `}
                `}
            >
                Utilize AI to transform complex process maps and tedious routine
                documents into intuitive workflows, empowering your team to
                operate more efficiently and easily improve your processes as
                your business evolves.
            </div>

            <div
                css={css`
                    position: relative;
                `}
            >
                <div
                    css={css`
                        background: conic-gradient(
                            from 230.29deg at 51.63% 52.16%,
                            var(--brand-base) 0deg,
                            #8b45f5 120deg,
                            #cc399b 220deg,
                            var(--brand-base) 360deg
                        );
                        filter: blur(140px);
                        transform: translateZ(0px) translate3d(0, 0, 0);
                        height: 100%;
                        width: 80%;
                        position: absolute;
                        top: 0;
                        z-index: -1;
                        opacity: 0.25;
                        margin: 0 auto;
                        inset: 0;
                    `}
                ></div>

                <div
                    css={css`
                        :before {
                            content: "";
                            position: absolute;
                            inset: 0;
                            border-radius: inherit;
                            padding: 1px;
                            background: linear-gradient(
                                180deg,
                                #7777ff40,
                                var(--fg-200)
                            );
                            -webkit-mask:
                                linear-gradient(#fff 0 0) content-box,
                                linear-gradient(#fff 0 0);
                            mask:
                                linear-gradient(#fff 0 0) content-box,
                                linear-gradient(#fff 0 0);
                            -webkit-mask-composite: xor;
                            -webkit-mask-composite: exclude;
                            mask-composite: exclude;
                            pointer-events: none;
                        }

                        border-radius: 8px;
                        margin-top: 64px;
                        background: linear-gradient(
                            180deg,
                            #16161688 0%,
                            #161616cc 100%
                        );

                        border: 1px solid var(--fg-200);
                        /*  box-shadow: 0px 4px 100px rgba(92, 43, 115, 0.2); */

                        ${lessThan(1)`
                        width: 100%;
                        margin-top: 40px;
                    `}
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            gap: 24px;
                            padding: 24px;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            color: #8f8f8f;
                            border-bottom: 1px solid var(--fg-300);

                            ${lessThan(1)`
                            font-size: 16px;
                            padding: 16px 12px;
                            gap: 12px;
                        `}
                        `}
                    >
                        <UseCaseItem
                            label="Text to flow"
                            value="textToFlow"
                            useCase={useCase}
                            setUseCase={setUseCase}
                            icon={<Text />}
                        />

                        <UseCaseItem
                            label="Image to flow"
                            value="imageToFlow"
                            useCase={useCase}
                            setUseCase={setUseCase}
                            icon={<Image />}
                        />

                        {/* <UseCaseItem
                        label="Document to flow"
                        value="docToFlow"
                        useCase={useCase}
                        setUseCase={setUseCase}
                    /> */}
                    </div>

                    {/* {error && <div>{error.message}</div>} */}
                    <form
                        ref={setFormRef}
                        onSubmit={handleSubmit(async values => {
                            setIsLoading(true)
                            analytics.track(Events2.client.GenerateAI, {
                                prompt: values.useCase,
                                actors: values.actors,
                                uploadedFile: !!values.file,
                                page: "Ai",
                            })

                            try {
                                await createAiProcess({
                                    options: {
                                        input: values.useCase,
                                        files: values.file ? [values.file] : [],
                                        actors: values.actors,
                                        stream: true,
                                    },
                                    onChunk: value => {
                                        const items = normalizeReadable({
                                            items: value.items,
                                        })

                                        setControlledState(
                                            baseFormat({
                                                name: value.name,
                                                items,
                                            })
                                        )
                                    },
                                    onSuccess: ({ result }) => {
                                        const id = crypto.randomUUID()
                                        setId(id)
                                        setIsLoading(false)
                                        if (result) {
                                            localStorage.setItem(
                                                `sx:ai:${id}`,
                                                JSON.stringify(result)
                                            )
                                        }
                                    },
                                })
                            } catch (error) {
                                setError("useCase", { message: error })
                            }
                        })}
                    >
                        <div
                            css={css`
                                display: grid;
                                grid-template-columns: 3fr 2fr;
                                gap: 24px;

                                ${lessThan(1)`
                                   grid-template-columns: 1fr;
                            `}
                            `}
                        >
                            <div
                                css={css`
                                    border-right: 1px solid var(--fg-300);
                                    padding: 24px 40px;

                                    ${lessThan(1)`
                                        border-right: none;
                                    `}
                                `}
                            >
                                {useCase === "textToFlow" && (
                                    <TextToFlowInput register={register} />
                                )}

                                {useCase === "imageToFlow" && (
                                    <ImageToFlowInput control={control} />
                                )}

                                {errorMessage && (
                                    <div
                                        css={css`
                                            font-size: 14px;
                                            color: var(--red-500);
                                        `}
                                    >
                                        {errors?.useCase?.message ||
                                            errors?.file?.message}
                                    </div>
                                )}

                                <LoadingButton
                                    isLoading={isLoading}
                                    variant={ButtonType.Primary}
                                    type="submit"
                                    css={css`
                                        gap: 8px;
                                        margin: 24px 0;
                                        background-color: transparent;
                                    `}
                                >
                                    <Stars width={16} height={16} />
                                    Generate flow
                                </LoadingButton>
                            </div>

                            <div
                                css={css`
                                    padding: 40px;

                                    ${lessThan(1)`
                                   display: none;
                                `}
                                `}
                            >
                                <Title4>Actors</Title4>
                                <div
                                    css={css`
                                        margin: 4px 0 24px;
                                        font-size: 13px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 24px; /* 184.615% */
                                    `}
                                >
                                    Which actors should be included? Actors are
                                    the roles and systems participating in the
                                    process.
                                </div>
                                <div>
                                    <ActorFields
                                        control={control}
                                        register={register}
                                    />
                                </div>
                                {/* <FormFileUpload name="files" label="Files" /> */}
                            </div>
                        </div>
                    </form>

                    {controlledState?.items?.length > 0 && (
                        <div
                            css={css`
                                border-top: 1px solid var(--fg-300);
                            `}
                        >
                            <AiRespons
                                controlledState={controlledState}
                                id={id}
                                isLoading={isLoading}
                                setProcessContainerRef={setProcessContainerRef}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div
                css={css`
                    margin-top: 40px;
                    padding: 40px;
                    border: 1px solid #fff;
                    border-radius: 6px;
                    border: 1px solid var(--fg-200);
                    box-shadow: 0px 4px 100px rgba(92, 43, 115, 0.2);
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 40px;

                    ${lessThan(1)`
                            display: flex;
                            flex-direction: column;
                            gap: 24px;
                        `}
                `}
            >
                <FaqItem title="How to get started?">
                    Start with describing your process or uploading a image of
                    an existing flow from another tool.
                </FaqItem>
                <FaqItem title="Can I edit my flows?">
                    When the flow is finished building, you can click on "edit
                    in ShiftX", and import it into ShiftX. If you don't have an
                    account, sign up, and the generated flow will show at the
                    top of the Setup page.
                </FaqItem>
                <FaqItem title="How can I share feedback?">
                    We'd love to hear from you. Reach out to us at
                    hello@shiftx.com,{" "}
                    <RouterLink
                        passHref
                        legacyBehavior
                        href="https://www.linkedin.com/company/shiftxcom/"
                    >
                        <Link>LinkedIn</Link>
                    </RouterLink>{" "}
                    or join our{" "}
                    <RouterLink
                        passHref
                        legacyBehavior
                        href="https://join.slack.com/t/shiftx-community/shared_invite/enQtOTU4ODc0Nzc3MjgwLTExYWEwM2MzNDgxZTI3NzQ3YTdiY2U3ZWZjM2RlZDcwNDRjY2E3MDY2NzFhOTkxNDk4YzdkNmQ3ZGIxZDU2YTc"
                    >
                        <Link>Slack Community</Link>
                    </RouterLink>
                </FaqItem>
                <FaqItem title="What types of flows can it make?">
                    Any process maps, customer journey maps, user flows or
                    routines are available.
                </FaqItem>
                <FaqItem title="Will my data be used for LLM training?">
                    No. OpenAI will not use your description or image to train
                    AI models. ShiftX may analyze your usage to improve and
                    enhance our AI feature.
                </FaqItem>
                <FaqItem title="What's next?">
                    It only gets better from here. We're working to improve
                    accuracy and layout customization. If there's something
                    you'd really like to see, drop us a note at
                    hello@shiftx.com.
                </FaqItem>
            </div>

            <div
                css={css`
                    margin-top: 40px;
                    border: 1px solid var(--fg-200);
                    padding: 40px;
                    border-radius: 6px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 40px;
                    background: rgba(185, 111, 221, 0.04);

                    ${lessThan(1)`
                        text-align: center;
                        flex-direction: column;
                        gap: 20px;
                    }`}
                `}
            >
                Experience the future of process improvement
                <RouterLink href="/signup">
                    <Button>Sign up for free</Button>
                </RouterLink>
            </div>

            <div
                css={css`
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 40px;
                    margin-top: 40px;

                    ${lessThan(1)`
                            display: flex;
                            gap: 24px;
                        `}
                `}
            >
                <ImproveUSP
                    textFirst={true}
                    color="red"
                    label={improve.blocks[1].label}
                    title={improve.blocks[1].title}
                    icon={
                        <Flag
                            width={20}
                            height={20}
                            css={css`
                                color: var(--red-500);
                            `}
                        />
                    }
                    preamble={improve.blocks[1].subtitle}
                    vertical={true}
                    illustration={<IssueUSP />}
                />

                <ImproveUSP
                    textFirst={false}
                    color="blue"
                    label={improve.blocks[2].label}
                    title={improve.blocks[2].title}
                    icon={
                        <Chat
                            width={20}
                            height={20}
                            css={css`
                                color: var(--blue-500);
                            `}
                        />
                    }
                    preamble={improve.blocks[2].subtitle}
                    vertical={true}
                    illustration={<CommentGUI />}
                />
            </div>

            <div
                css={css`
                    margin-top: 40px;
                `}
            >
                <TripletUSP content={mapping} />
            </div>
        </div>
    )
}

const FaqItem = ({ title, children }) => {
    return (
        <div>
            <Title4>{title}</Title4>
            <div
                css={css`
                    margin-top: 8px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 171.429% */
                `}
            >
                {children}
            </div>
        </div>
    )
}

export const ActorFields = ({ control, register }) => {
    const { fields, append, prepend, remove, swap, move, insert } =
        useFieldArray({
            control, // control props comes from useForm (optional: if you are using FormContext)
            name: "actors", // unique name for your Field Array
        })

    return (
        <div>
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;
                `}
                onClick={() => append({ value: "" })}
            >
                <div
                    css={css`
                        border-radius: 50%;
                        border: 1px solid var(--fg-300);
                        padding: 4px;
                        display: inline-block;
                    `}
                >
                    <Plus width={16} height={16} />
                </div>
                <div
                    css={css`
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px; /* 184.615% */
                    `}
                >
                    Name a role, team or system etc..
                </div>
            </div>
            <div
                css={css`
                    display: grid;
                    gap: 8px;
                    margin-top: 24px;
                `}
            >
                {fields.map((field, index) => (
                    <div
                        css={css`
                            display: flex;
                            gap: 8px;
                            align-items: center;
                        `}
                    >
                        <Input
                            onKeyDown={e => {
                                if (e.key === "Enter") {
                                    e.preventDefault()
                                    e.currentTarget.blur()
                                }
                            }}
                            autoFocus={true}
                            key={field.id} // important to include key with field's id
                            {...register(`actors.${index}.value`)}
                        />
                        <div
                            css={css`
                                border-radius: 50%;
                                border: 1px solid var(--fg-300);
                                padding: 4px;
                                display: inline-block;
                                place-items: center;
                                width: 24px;
                                height: 24px;
                                cursor: pointer;
                            `}
                            onClick={() => {
                                remove(index)
                            }}
                        >
                            <Minus width={16} height={16} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const AiRespons = ({
    controlledState,
    setProcessContainerRef,
    isLoading,
    id,
    options = {},
    theme = "dark",
    children,
}: {
    controlledState: any
    options?: ProcessOptions
    setProcessContainerRef?: (ref: HTMLDivElement) => void
    isLoading?: boolean
    id?: string
    theme?: "dark" | "light"
    children?: React.ReactNode
}) => {
    return (
        <div
            css={css`
                display: relative;
            `}
        >
            <ExternalProcess
                controlledState={controlledState}
                options={{
                    width: "100%",
                    height: "550px",
                    defaultZoom: 1,
                    showControls: false,
                    backgroundColor: "transparent",
                    editorMode: ProcessScopeMode.View,
                    animate: isLoading,
                    ...options,
                }}
                theme={theme}
            >
                <div
                    ref={setProcessContainerRef}
                    /* css={css`
                        border-top: 1px solid var(--fg-300);
                    `} */
                >
                    {children}
                </div>
            </ExternalProcess>
            {!isLoading && id && (
                <div
                    css={css`
                        position: absolute;
                        transform: translateX(-50%);
                        left: 50%;
                        bottom: -20px;
                    `}
                >
                    <RouterLink
                        href={`/processes/new?importId=${id}`}
                        onClick={() => {
                            analytics.track(Events2.client.EditInShiftx, {})
                        }}
                    >
                        <Button
                            css={css`
                                border-color: var(--blue-500);
                                background-color: transparent;

                                border-radius: var(--Radius-XL, 24px);

                                height: 40px;
                                font-size: 14px;
                                font-family: Inter;

                                border: 1px solid rgba(168, 75, 212, 0.4);

                                background: linear-gradient(
                                    92deg,
                                    rgba(168, 75, 212, 0.4) 0%,
                                    rgba(64, 64, 240, 0.4) 100%
                                );

                                backdrop-filter: blur(10px);
                            `}
                        >
                            <span
                                css={css`
                                    background: linear-gradient(
                                        90deg,
                                        #cf9ce7 0%,
                                        #a0a0f8 100%
                                    );

                                    :hover {
                                        background: linear-gradient(
                                            90deg,
                                            #d3c5d9 0%,
                                            #d2d2e7 100%
                                        );

                                        background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                    }

                                    background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                `}
                            >
                                Edit in ShiftX
                            </span>
                        </Button>
                    </RouterLink>
                </div>
            )}
        </div>
    )
}

const items = normalizeReadable({
    name: "Business Process for Two Entities",
    items: [
        {
            narrative:
                '<actor name="Client Manager" icon="fas-user" description="responsible for managing client relationships" category="company"></actor> reviews client requirements',
            splitType: "decision",
            paths: [
                {
                    label: "Entity A",
                    items: [
                        {
                            narrative:
                                '<actor name="Financial Controller" icon="fas-user-ninja" description="oversees financial reporting" category="company"></actor> prepares financial assessment',
                            splitType: "decision",
                            paths: [
                                {
                                    label: "Assessment Positive",
                                    items: [
                                        {
                                            narrative:
                                                '<actor name="Senior Approver" icon="fas-users" description="senior person responsible for final approval" category="company"></actor> approves the project',
                                        },
                                    ],
                                    probability: 70.0,
                                    onSuccess: { kind: "none" },
                                },
                                {
                                    label: "Assessment Negative",
                                    items: [
                                        {
                                            narrative:
                                                '<actor name="Client Manager" icon="fas-user" description="responsible for managing client relationships" category="company"></actor> provides feedback to client',
                                        },
                                    ],
                                    probability: 30.0,
                                    onSuccess: {
                                        kind: "loop",
                                        loopToNarrative:
                                            '<actor name="Client Manager"></actor> reviews client requirements',
                                    },
                                },
                            ],
                            splitType: "decision",
                        },
                    ],
                    probability: 50.0,
                    onSuccess: { kind: "none" },
                },
                {
                    label: "Entity B",
                    items: [
                        {
                            narrative:
                                '<actor name="Financial Controller" icon="fas-user-ninja" description="oversees financial reporting" category="company"></actor> conducts risk analysis',
                            splitType: "decision",
                            paths: [
                                {
                                    label: "Low Risk",
                                    items: [
                                        {
                                            narrative:
                                                '<actor name="Senior Approver" icon="fas-users" description="senior person responsible for final approval" category="company"></actor> signs off on the project',
                                        },
                                    ],
                                    probability: 80.0,
                                    onSuccess: { kind: "none" },
                                },
                                {
                                    label: "High Risk",
                                    items: [
                                        {
                                            narrative:
                                                '<actor name="Client Manager" icon="fas-user" description="responsible for managing client relationships" category="company"></actor> negotiates terms with client',
                                        },
                                    ],
                                    probability: 20.0,
                                    onSuccess: {
                                        kind: "loop",
                                        loopToNarrative:
                                            '<actor name="Client Manager"></actor> reviews client requirements',
                                    },
                                },
                            ],
                            splitType: "decision",
                        },
                    ],
                    probability: 50.0,
                    onSuccess: { kind: "none" },
                },
            ],
            splitType: "decision",
        },
    ],
})
