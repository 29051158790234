import { ActorCategoryId, ActorCategoryName } from "@shiftx/types"
import { notationColors } from "./notationColors"

export const actorCategories = [
    {
        id: ActorCategoryId.Company,
        name: ActorCategoryName.Company,
        color: notationColors.blue.default,
        cssColorVar: `actor-category-icon-${ActorCategoryId.Company}`,
    },
    {
        id: ActorCategoryId.Customer,
        name: ActorCategoryName.Customer,
        color: notationColors.pink.default,
        cssColorVar: `actor-category-icon-${ActorCategoryId.Customer}`,
    },
    {
        id: ActorCategoryId.CustomerContext,
        name: ActorCategoryName.CustomerContext,
        color: notationColors.turquoise.default,
        cssColorVar: `actor-category-icon-customer-context`,
    },
    {
        id: ActorCategoryId.System,
        name: ActorCategoryName.System,
        color: notationColors.yellow.default,
        cssColorVar: `actor-category-icon-${ActorCategoryId.System}`,
    },
    {
        id: ActorCategoryId.Reseller,
        name: ActorCategoryName.Reseller,
        color: notationColors.green.default,
        cssColorVar: `actor-category-icon-${ActorCategoryId.Reseller}`,
    },
    {
        id: ActorCategoryId.Supplier,
        name: ActorCategoryName.Supplier,
        color: notationColors.purple.default,
        cssColorVar: `actor-category-icon-${ActorCategoryId.Supplier}`,
    },
    {
        id: ActorCategoryId.Competitor,
        name: ActorCategoryName.Competitor,
        color: notationColors.red.default,
        cssColorVar: `actor-category-icon-${ActorCategoryId.Competitor}`,
    },
    {
        id: ActorCategoryId.Government,
        name: ActorCategoryName.Government,
        color: notationColors.brown.default,
        cssColorVar: `actor-category-icon-${ActorCategoryId.Government}`,
    },
    {
        id: ActorCategoryId.Custom,
        name: ActorCategoryName.Custom,
        color: notationColors.gray.default,
        cssColorVar: `actor-category-icon-${ActorCategoryId.Custom}`,
    },
]
