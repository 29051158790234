import { userInputText } from "../prompts/userInputText"
import { createSystemInput } from "./createSystemInput"

export const createFineTunedItem = ({ flowType, title, result }) => {
    return {
        messages: [
            {
                role: "system",
                content: createSystemInput(),
            },
            {
                role: "user",
                content: userInputText({
                    description: title,
                    type: flowType,
                }),
            },
            {
                role: "assistant",
                content: `${JSON.stringify(result)}`,
            },
        ],
    }
}
