import { css, styled, getThemeProp, TextStyle } from "@shiftx/styles"
import React, { ButtonHTMLAttributes } from "react"
import { Spinner } from "../Spinner/Spinner"

type ButtonTypeExtends = {
    disabled?: boolean
    showDot?: boolean
    fullWidth?: boolean
}

export const SimpleButton = styled("button")<ButtonTypeExtends>`
    ${props => {
        return getThemeProp(props.theme, TextStyle.TextMedium)
    }};
    appearance: none;
    text-decoration: none;
    text-align: left;
    background-color: transparent;
    border: none;
    color: var(--fg-high);
    position: relative;
    justify-content: center;
    border-radius: 4px;
    align-items: center;
    display: inline-flex;
    padding: 6px;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    max-width: ${props => (props.fullWidth ? `none` : `fit-content`)};
    margin: 0;
    transition:
        box-shadow 0.2s,
        border 0.2s,
        border-color 0.2s;

    ${props =>
        !props.disabled &&
        css`
            cursor: pointer;
        `};

    :focus-visible {
        outline: none;
        border-color: transparent;
        box-shadow: 0px 0px 0px 1px var(--blue-700);
    }

    white-space: nowrap;
    vertical-align: middle;

    ${props =>
        props.showDot &&
        css`
            :after {
                content: "";
                position: absolute;
                right: 8px;
                top: -7px;
                width: 10px;
                height: 10px;
                border: 2px solid #fff;
                border-radius: 50%;
                background-color: var(--brand-base);
            }
        `}
`

const BaseButton = styled(SimpleButton)<ButtonTypeExtends>`
    padding: 6px 12px;
    height: 36px;
`

export enum ButtonType {
    Primary = "primary",
    Secondary = "secondary",
    Red = "red",
    Green = "green",
    Filled = "Filled",
}

const PrimaryButton = styled(BaseButton)<ButtonProps>`
    background-color: var(--bg-primary);
    color: var(--blue-500);
    border: 1px solid var(--blue-500);

    ${props =>
        !props.disabled &&
        css`
            :hover {
                color: var(--blue-700);
                border: 1px solid var(--blue-700);
            }
        `}

    ${props =>
        props.disabled &&
        css`
            color: var(--blue-300);
            border-color: var(--blue-300);
        `}
`
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode | React.ReactNode[]
    variant?: ButtonType
    onClick?: (event?: React.MouseEvent) => void
    showDot?: boolean
    disabled?: boolean
    fullWidth?: boolean
}

const SecondaryButton = styled(BaseButton)<ButtonProps>`
    background-color: var(--bg-primary);
    border: 1px solid var(--fg-300);

    ${props =>
        !props.disabled &&
        css`
            :hover {
                border-color: var(--fg-400);
            }
        `}

    ${props =>
        props.disabled &&
        css`
            color: var(--fg-low);
        `}
`
const RedButton = styled(BaseButton)<ButtonProps>`
    background-color: var(--bg-primary);
    color: var(--red-500);
    border: 1px solid var(--red-500);

    ${props =>
        !props.disabled &&
        css`
            :hover {
                color: var(--red-700);
                border-color: var(--red-700);
            }
        `}

    ${props =>
        props.disabled &&
        css`
            color: var(--red-300);
            border-color: var(--red-300);
        `}
`

const GreenButton = styled(BaseButton)<ButtonProps>`
    background-color: var(--bg-primary);
    color: var(--green-500);
    border: 1px solid var(--green-500);

    ${props =>
        !props.disabled &&
        css`
            :hover {
                color: var(--green-700);
                border-color: var(--green-700);
            }
        `}

    ${props =>
        props.disabled &&
        css`
            color: var(--green-300);
            border-color: var(--green-300);
        `}
`

const Filled = styled(BaseButton)<ButtonProps>`
    background-color: var(--blue-500);
    color: #fff;

    ${props =>
        props.disabled &&
        css`
            background-color: var(--blue-300);
        `}

    ${props =>
        !props.disabled &&
        css`
            :hover {
                background-color: var(--blue-600);
            }
        `}
`

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ children, variant = ButtonType.Secondary, ...props }, ref) => {
        switch (variant) {
            case ButtonType.Primary:
                return (
                    <PrimaryButton type="button" ref={ref} {...props}>
                        {children}
                    </PrimaryButton>
                )
            case ButtonType.Red:
                return (
                    <RedButton type="button" ref={ref} {...props}>
                        {children}
                    </RedButton>
                )
            case ButtonType.Green:
                return (
                    <GreenButton type="button" ref={ref} {...props}>
                        {children}
                    </GreenButton>
                )
            case ButtonType.Secondary:
                return (
                    <SecondaryButton type="button" ref={ref} {...props}>
                        {children}
                    </SecondaryButton>
                )
            case ButtonType.Filled:
                return (
                    <Filled type="button" ref={ref} {...props}>
                        {children}
                    </Filled>
                )
        }
    }
)

interface LoadingButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode | React.ReactNode[]
    variant?: ButtonType
    isLoading?: boolean
    spinnerColor?: string
    showDot?: boolean
    disabled?: boolean
    onClick?: (event?: React.MouseEvent) => void
}

export const LoadingButton = ({
    variant = ButtonType.Secondary,
    isLoading = false,
    children,
    spinnerColor,
    showDot,
    ...rest
}: LoadingButtonProps) => {
    return (
        <Button
            variant={variant}
            disabled={isLoading}
            {...rest}
            showDot={isLoading ? false : showDot}
        >
            {isLoading && <Spinner />}
            {!isLoading && children}
        </Button>
    )
}
