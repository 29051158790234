import { Entity, ProcessItem, Ref, VariantRef } from "@shiftx/types"
import { convertActorsFromNarrativeToObjects } from "./getActorsFromNarratives"
import { BaseFormat } from "./types"

export const baseFormat = ({
    name = "",
    items,
    actors = [],
}: {
    name?: string
    items: ProcessItem[]
    actors?: any[]
}): BaseFormat => {
    const { actors: newActors, items: newItems } =
        convertActorsFromNarrativeToObjects({
            items,
            actors,
        })

    return {
        ref: "Process/tmp-process",
        entity: Entity.Process,
        name,
        items: newItems,
        actors: newActors,
        contentBlocks: [],
        files: [],
        defaultVariant: {
            ref: "ProcessVariant/tmp-variant",
            entity: Entity.Variant,
            onSuccess: {
                kind: "done",
            },
        },
    }
}
