import { css } from "@shiftx/styles"
import { lessThan } from "../../../utils/breakpoints"
import { Title4 } from "../../../components/Text"
import { useEffect, useState } from "react"

export const TextToFlowInput = ({ register }) => {
    const [focusRef, setRef] = useState(null)
    useEffect(() => {
        if (focusRef) {
            focusRef.focus({ preventScroll: true })
        }
    }, [focusRef])

    const { ref, ...registerField } = register("useCase", {
        required: "Description is required",
    })

    return (
        <div>
            <Title4>Describe the process</Title4>
            <div
                css={css`
                    margin-top: 8px;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 24px;
                `}
            >
                Use plain text to describe the process you want to create. The
                more specific you write, the more detailed the flow will be. Or
                start with one of our presets.
            </div>
            <textarea
                ref={e => {
                    ref(e)
                    setRef(e)
                }}
                {...registerField}
                placeholder={"Sending out an invoice to customer"}
                autoFocus={false}
                defaultValue={""}
                css={css`
                    min-height: 120px;
                    color: #fff;
                    box-sizing: border-box;
                    border: none;
                    resize: none;
                    max-width: 100%;
                    min-height: 50px;
                    width: 100%;
                    border-radius: 4px;
                    background-color: var(--fg-300-solid);
                    border: 1px solid var(--fg-100);
                    padding: 24px;
                    background-color: var(--fg-100);
                    margin: 0;

                    ${lessThan(1)`
                    padding: 16px;
                `}

                    ::placeholder {
                        color: var(--fg-low);
                    }

                    &:focus {
                        outline: none;

                        ::placeholder {
                            color: var(--fg-low);
                            opacity: 0.5;
                        }
                    }

                    :hover,
                    :focus {
                        background-color: var(--fg-200);
                        border-color: var(--fg-200);
                    }

                    :read-only {
                        color: var(--fg-low);
                    }
                `}
            />
        </div>
    )
}
