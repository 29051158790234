export const actorIconsShim = {
    Account: "fas-user",
    AccountMultiple: "fas-user-group",
    AccountGroup: "fas-users",
    Pirate: "fas-skull-crossbones",
    Ninja: "fas-user-ninja",
    Store: "fas-store",
    Garage: "fas-garage",
    HomeVariant: "fas-house-chimney",
    HomeModern: "fas-building",
    Domain: "fas-buildings",
    Bank: "fas-landmark",
    Factory: "fas-industry-windows",
    City: "fas-city",
    Server: "fas-server",
    Robot: "fas-robot",
    Brain: "fas-brain",
    CloudOutline: "fas-cloud",
    Cogs: "fas-gears",
    Database: "fas-database",
    DesktopMac: "fas-desktop",
    Laptop: "fas-laptop",
    Cellphone: "fas-mobile-screen-button",
}
