import { Button } from "@shiftx/components"
import { Upload } from "@shiftx/icons"
import { css } from "@shiftx/styles"
import { Fragment, useRef, useState } from "react"

interface Props {
    value: string
    onChange: () => void
}

const convertBase64 = file => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
            resolve(fileReader.result)
        }

        fileReader.onerror = error => {
            reject(error)
        }
    })
}

const validTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/x-icon",
]

export const ImageUpload = ({ value = "", onChange = null }: Props) => {
    const errors = []

    const [previewImage, setPreviewImage] = useState(null)
    const [hightlight, setHighlighted] = useState(false)
    const inputRef = useRef(null)

    const dragOver = e => {
        e.preventDefault()
        setHighlighted(true)
    }

    const dragEnter = e => {
        e.preventDefault()
    }

    const dragLeave = e => {
        e.preventDefault()
        setHighlighted(false)
    }

    const fileReader = async (files: File[]) => {
        const filesConverted = Array.from(files).map(async file => {
            const text = await convertBase64(file)

            return {
                name: file.name,
                size: file.size,
                lastModified: file.lastModified,
                type: file.type,
                content: text,
            }
        })

        return Promise.all(filesConverted)
    }

    const fileBrowse = (event, onChange) => {
        event.preventDefault()
        const files = event.target.files
        fileReader(files).then(res => {
            onChange(res[0])
            setPreviewImage(res[0].content)
        })
    }

    const fileDrop = (event, onChange) => {
        event.preventDefault()
        const files = event.dataTransfer.files
        fileReader(files).then(res => {
            onChange(res[0])
            setPreviewImage(res[0].content)
            console.log("fileBrowse", res)
        })
        setHighlighted(false)
    }

    const validateFileType = file => {
        if (validTypes.includes(file.type)) {
            return true
        }
        return false
    }

    const validateFileSize = file => {
        // 5 MB limit
        if (file.size > 5242880) {
            return false
        }
        return true
    }

    return (
        <Fragment>
            {previewImage && (
                <Fragment>
                    <div
                        css={css`
                            display: flex;
                            position: relative;
                            width: 100%;
                            height: 100%;
                            max-height: calc(90vh - 48px);
                            max-width: calc(90vw - 320px - 48px);
                            padding: 0;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        `}
                    >
                        <img
                            id="previewImage"
                            src={previewImage}
                            width="100%"
                            css={css`
                                display: flex;
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            `}
                        />
                        <Button
                            onClick={() => {
                                setPreviewImage(null)
                                onChange(null)
                            }}
                            css={css`
                                position: absolute;
                                bottom: 2%;
                                opacity: 70%;
                                :hover {
                                    transform: scale(1.05);
                                    opacity: 100%;
                                }
                                transition: all 100ms ease-in-out;
                            `}
                        >
                            Remove
                        </Button>
                    </div>
                </Fragment>
            )}

            <input
                css={css`
                    height: 0;
                    overflow: hidden;
                    display: none;
                `}
                type="file"
                accept={validTypes.join(", ")}
                /* {...register(name, validation)} */
                onChange={event => fileBrowse(event, onChange)}
                ref={inputRef}
            />

            {!previewImage && (
                <div
                    onClick={() => {
                        inputRef.current.click()
                    }}
                    css={css`
                        display: flex;
                        flex: 1;
                        text-align: center;
                        height: 100%;
                        width: 100%;
                        cursor: pointer;
                        border-radius: 4px;
                        color: var(--fg-500);
                        background-color: var(--fg-100);
                        border: 1px solid var(--fg-200);
                        :hover {
                            color: var(--fg-400);
                            border: 1px solid var(--fg-300);
                        }
                        transition: all 100ms ease-in-out;
                        ${hightlight &&
                        css`
                            transform: scale(1.02);
                            border-color: var(--bg-primary);
                            color: #ffffff;
                            cursor: copy;
                        `};
                    `}
                    className="drop-container"
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={event => {
                        fileDrop(event, onChange)
                    }}
                >
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            gap: 16px;
                            flex: 1;
                            align-self: center;
                            align-items: center;
                            font-size: 16px;
                            color: inherit;
                        `}
                    >
                        <Upload width={24} height={24} />
                        Drop your image here, or click to select file...
                        {/*  {errors[name] && (
                            <FormErrorMessage
                                css={css`
                                    font-size: 12px;
                                `}
                                role="alert"
                            >
                                {errors[name].message}
                            </FormErrorMessage>
                        )}
                        {errors["unsupportedFileFormat"] && (
                            <FormErrorMessage
                                css={css`
                                    font-size: 12px;
                                `}
                                role="alert"
                            >
                                {errors["unsupportedFileFormat"].message}
                            </FormErrorMessage>
                        )}
                        {errors["fileSizeTooLarge"] && (
                            <FormErrorMessage
                                css={css`
                                    font-size: 12px;
                                `}
                                role="alert"
                            >
                                {errors["fileSizeTooLarge"].message}
                            </FormErrorMessage>
                        )} */}
                    </div>
                </div>
            )}
        </Fragment>
    )
}
