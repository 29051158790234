import { createSystemInput } from "./utils/createSystemInput"
import { createUserInput } from "./utils/createUserInput"
import { streamResult } from "./utils/streamResult"

export type OnSuccessCallback = ({
    finish_reason,
    result,
}: {
    finish_reason: string
    result: object
}) => void

export const createAiProcess = async ({
    options: {
        input = "Sending out a invoice to customer",
        actors = [],
        files = [],
        tools = null,
        tool_choice = null,
        stream = false,
    },
    onChunk,
    onSuccess,
}: {
    options: {
        input: string
        actors?: Array<string>
        files?: Array<File>
        tools?: Array<string>
        tool_choice?: string
        stream?: boolean
    }
    onChunk?: (chunk: string) => void
    onSuccess?: OnSuccessCallback
}) => {
    const systemInput = createSystemInput()
    const userInput = createUserInput({
        input,
        hasImage: files.length > 0,
        actors,
    })
    const response = await fetch("/aiApi", {
        method: "POST",
        body: JSON.stringify({
            userInput,
            systemInput,
            files,
            tools,
            tool_choice,
        }),
    })

    const reader = response.body.getReader()

    streamResult({
        reader,
        callback: value => {
            if (stream) {
                if (value?.error) {
                    throw value.error
                } else {
                    onChunk(value)
                }
            }
        },
        onSuccess,
        isTools: files.length === 0 && tools?.length > 0,
    })
}
