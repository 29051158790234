import { Entity, SplitType } from "@shiftx/types"
import type { ReadableItem } from "./types"

export const normalizeReadable = ({
    items,
    isRoot = true,
    sequenceRef = "ProcessItem/tmp-sequence-root",
}: {
    items: ReadableItem[]
    isRoot?: boolean
    sequenceRef?: string
}) => {
    const rootSequence = {
        ref: sequenceRef,
        entity: "Sequence",
        itemRefs:
            items?.map(
                (item, indexRoot) =>
                    `ProcessItem/tpm-item-${
                        sequenceRef.split("/")[1]
                    }-${indexRoot}`
            ) ?? [],
        isRoot,
    }

    const allItems = addItem({ items, sequenceRef: rootSequence.ref }) ?? []
    return [rootSequence, ...allItems]
}

const tempItems = []
const addItem = ({ items, sequenceRef: parentSequenceRef }) => {
    return items
        ?.map((item, indexRoot) => {
            const ref = `ProcessItem/tpm-item-${
                parentSequenceRef.split("/")[1]
            }-${indexRoot}`
            if (item.paths) {
                const processItem = {
                    propability: item.probability,
                    pathRefs:
                        item?.paths?.map((path, index) => {
                            return `ProcessItem/tmp-path-${index}-${indexRoot}-${
                                parentSequenceRef.split("/")[1]
                            }`
                        }) ?? [],
                    actorRefs: item.actorRef ?? [],
                    entity: Entity.Split,
                    kind: "split",
                    splitType: SplitType.Decision,
                    ref,
                    narrative: item.narrative,
                }
                tempItems.push(processItem)

                const moreItems = []
                item?.paths?.forEach?.((path, index) => {
                    const sequenceRef = `ProcessItem/tmp-sequence-${index}-${indexRoot}-${
                        parentSequenceRef.split("/")[1]
                    }`
                    const pathRef = `ProcessItem/tmp-path-${index}-${indexRoot}-${
                        parentSequenceRef.split("/")[1]
                    }`

                    const loopToNarrative = tempItems.find(
                        item =>
                            item.narrative === path?.onSuccess?.loopToNarrative
                    )

                    const onSuccess = (kind, loopToNarrative) => {
                        if (kind === "loop" && loopToNarrative) {
                            return { kind, loopToRef: loopToNarrative?.ref }
                        } else if (kind) {
                            return { kind }
                        } else {
                            return {
                                kind: "none",
                            }
                        }
                    }

                    moreItems.push({
                        ref: pathRef,
                        actorRefs: [],
                        entity: Entity.Path,
                        kind: "path",
                        sequenceRef,
                        onSuccess: onSuccess(
                            path?.onSuccess?.kind,
                            loopToNarrative
                        ),
                        label: path.label,
                        probability: path.probability,
                    })

                    const nestedItems = normalizeReadable({
                        items: path.items,
                        isRoot: false,
                        sequenceRef,
                    })
                    nestedItems.forEach(item => {
                        moreItems.push(item)
                    })
                }) ?? []

                return [...moreItems, processItem]
            } else {
                const processItem = {
                    ...item,
                    narrative: item.narrative,
                    duration: item.duration,
                    entity: Entity.Step,
                    kind: "step",
                    ref,
                    actorRefs: item.actorRef ?? [],
                }

                tempItems.push(processItem)
                return [processItem]
            }
        })
        .flat()
}
