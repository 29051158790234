export const processExampleTypescript = `
interface ReadableStep {
    narrative: string;
}

interface ReadableSplit {
    narrative: string;
    paths: ReadablePath[];
    splitType: "decision" | "parallel";
}

type ReadableItem = ReadableStep | ReadableSplit

type OnSuccessEnd = 
    kind: "none";
}

type OnSuccessLoop = {
    kind: "loop";
    loopToNarrative: string; //Exactly the same narrative of the step to loop back to
}

interface ReadablePath {
    label: string;
    items: ReadableItem[];
    probability: number; //Number between 0 and 100. Only one decimal
    onSuccess: { 
        kind: OnSuccessEnd | OnSuccessLoop
    }
}

interface Response {
    name: string;
    items: ReadableItem[];
}

`
