export const userInputImage = ({ description = null, actors }) => {
    return `Make a workflow based on the text and content of the attached image. 
    Don't include anything other the exactly whats in the image. 
    You can do word and sentence improvements.
    
    ${
        description
            ? `Description of the flow for more context in addition to the image:
                ${description}`
            : ``
    }
   

    Use all the provided actors. Everyone is required to be involved in the process: 
    ${actors
        .map(actors => {
            return `${actors.value}`
        })
        .join("\n")}
        
    You may also use other actors if necessary.`
}
