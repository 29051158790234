import { ImageUpload } from "@shiftx/components"
import { css } from "@shiftx/styles"
import { Title4 } from "../../../components/Text"
import { Controller } from "react-hook-form"

export const ImageToFlowInput = ({ control }) => {
    return (
        <div>
            <Title4>Image upload</Title4>
            <div
                css={css`
                    margin-top: 8px;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 24px;
                `}
            >
                Take a picture of a whiteboard-diagram or use a screenshot of a
                process map you have laying around in Miro, Visio or any other
                software. Or just use one of our presets to get started.
            </div>
            <div
                css={css`
                    height: 248px;
                    margin-bottom: 8px;
                `}
            >
                <Controller
                    control={control}
                    name="file"
                    rules={{ required: "Image is required" }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ImageUpload
                            value=""
                            onChange={value => {
                                onChange(value)
                            }}
                        />
                    )}
                />
            </div>
        </div>
    )
}
