export const userInputText = ({
    description,
    type = "business process",
    actors,
}) => {
    return `Create a flow for a ${type}. The description of the flow is "${description}".
    Create at least 5  steps, and at least 1 split. Choose this randomly.
    
    ${
        actors.length > 0 &&
        `
    Use the actors from the list that are appropriate based on the description: 
    ${actors
        .map(actor => {
            return `${actor}`
        })
        .join("\n")}
        
    You may also use other actors if necessary.`
    }`
}
