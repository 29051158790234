import { actorCategories, actorIconsShim } from "@shiftx/data"
import { ActorCategoryId } from "@shiftx/types"
import { processExampleTypescript } from "./exampleFormat"

const icons = Object.values(actorIconsShim)
    .map(icon => {
        return `"${icon}"`
    })
    .join(" | ")

const categories = actorCategories
    .filter(category => {
        return (
            category.id === ActorCategoryId.Company ||
            category.id === ActorCategoryId.Customer ||
            category.id === ActorCategoryId.System ||
            category.id === ActorCategoryId.Government
        )
    })
    .map(category => {
        return `"${category.id}"`
    })
    .join(" | ")

const typescriptActor = `interface Actor {
        name: string
        icon: ${icons}
        description: string
        category: ${categories}
    }`

/* This can be business processes, customer journeys or routines or procedures */

export const newSystemPrompt = `
Create a very detailed flow based on the user input. 

Create a flow in the following format:
The JSON should dynamically adjust to include a variety of steps and decision splits. 

A flow consist of steps and splits based on the Typescript interfaces "ReadableStep" and "ReadableSplit".
A step contains a narrative that describe whats should be done. Example: "<actor name="HR system></actor> fills out the form". Don't use period on last sentence.
A split is used when you need to choose between what path to take. Example: "Is <actor name="employee"></actor> qualified?"
A split contains paths, and paths contains steps and splits. Splits can be recursive. Example: Split -> [Path -> [Step, Step, Split -> [path -> [Step, Step]]]] -> step
If a split is created, add minimum two paths.

Follow exactly this TypeScript schema, and use type "Response" as root in the JSON:
${JSON.stringify(processExampleTypescript)}

The output should be flexible to add more steps or nested splits based on the complexity of the process. 
Use this description to guide the generation but do not limit the number of steps or the depth of nested splits.
____

Identify roles in the narrative. A person, department or system. Wrap the role in an "actor" html tag. The content of the tag shall be empty.
Add attributes to the html tag based on the typescript Interface "Actor": ${typescriptActor}. All attributes are required. Example: <actor name="HR system" icon="fas-gear" description="system for hr managment" category="company"></actor>
Only use one of icons that are provided in the typescript interface.
____

Only return a JSON in a single-line without whitespaces.
Don't use markdown. Just return a clean JSON. No \`\`\`
____

`
