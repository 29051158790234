import { openAiRequest } from "./openAiRequest"
import { openAIToJSON } from "./utils/openAIToJSON"
import { streamResult } from "./utils/streamResult"

export const optimizeProcess = async ({ items, callBack }) => {
    const response = await openAiRequest({
        stream: true,

        systemInput: `Based on the JSON given to you by the user, give me a structure JSON with insight of each step.

    1. Process Automation Suggestions: analyze the flowchart and suggest which steps can be automated. Give me what method that should be used, and also suggestion to specific softwares that can be used
    
    2. Optimization Insights: provide insights on the current process' efficiency by analyzing average completion times and suggesting optimizations. For example, the AI could identify if the 'review' step is a bottleneck and suggest adding more resources there. Make an estimate on how long each step would take to finish.
    
    The output should be a clean JSON, without any text or markdown. Just the JSON.
    Use the ref to identfy each step.
    
   Use exactly this format, but length can be different: 
   
   ${exampleFormat}`,
        userInput: `The process to be optimezed is the following:
        [${items
            .filter(item => item.narrative)
            .map((item, index) => {
                return `{ ref: ${item.ref}, narrative: ${item.narrative}}`
            })}]`,
    })

    streamResult({
        response,
        callback: value => {
            console.log("value", value)
            callBack(value)
        },
    })

    const output = await response.json()
    return openAIToJSON({ output })
}

const exampleFormat = {
    ProcessAutomationSuggestions: {
        ref1: {
            automationMethod: "",
            software: [],
        },
        ref2: {
            automationMethod: "",
            software: [],
        },
    },
    OptimizationInsights: {
        ref1: {
            estimatedCompletionTime: "",
            optimizationSuggestion: "",
        },
        ref2: {
            estimatedCompletionTime: "",
            optimizationSuggestion: "",
        },
    },
}
