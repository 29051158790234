import jsonAutocomplete from "json-autocomplete"
import { OnSuccessCallback } from "../createAiProcess"

export const streamResult = async ({
    reader,
    isTools = false,
    callback,
    onSuccess,
}: {
    reader: any
    isTools?: boolean
    callback?: (value: string) => void
    onSuccess?: OnSuccessCallback
}) => {
    const decoder = new TextDecoder("utf-8")
    let resultText = ""

    while (true) {
        const { done, value } = await reader.read()
        if (done) {
            break
        }

        const chunk = decoder.decode(value)
        const lines = chunk.split("--split--")

        for (const line of lines) {
            if (line) {
                let chunkParsed
                try {
                    chunkParsed = JSON.parse(line)
                } catch (error) {
                    console.log("error parsing line", {
                        error,
                        resultText,
                        chunk,
                    })
                    throw error
                }

                const finishReason = chunkParsed?.finish_reason
                if (finishReason) {
                    onSuccess?.({
                        finish_reason: finishReason,
                        result: JSON.parse(resultText),
                    })
                } else {
                    const delta = chunkParsed.delta.content
                    resultText += delta
                    try {
                        if (!chunk.includes("<")) {
                            const completeJSON = jsonAutocomplete(resultText)
                            callback?.(JSON.parse(completeJSON))
                        }
                    } catch (error) {
                        console.log(
                            "error autocomplete JSON",
                            error,
                            resultText
                        )
                    }
                }
            }
        }
    }
}
