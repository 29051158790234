import { userInputImage } from "../prompts/userInputImage"
import { userInputText } from "../prompts/userInputText"

export const createUserInput = ({ input, hasImage, actors }) => {
    if (hasImage) {
        return userInputImage({ description: input, actors })
    } else {
        return userInputText({ description: input, actors })
    }
}
