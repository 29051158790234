export const getActorsFromNarrative = (value = "") => {
    const actors = value?.match(/<actor([^<]+)>*<\/actor>/g)

    if (typeof DOMParser !== "undefined") {
        return actors
            ? [
                  ...new DOMParser()
                      .parseFromString(actors, "text/html")
                      .getElementsByTagName("actor"),
              ].map(item => {
                  const name = item.textContent || item.getAttribute("name")
                  return {
                      id: item.getAttribute("id"),
                      name: name ?? "No content",
                      item: item.outerHTML,
                      icon: item.getAttribute("icon"),
                      description: item.getAttribute("description"),
                      category: item.getAttribute("category"),
                  }
              })
            : []
    } else {
        return []
    }
}

const addActorsToNarrative = ({ narrative, actors }) => {
    const findActorsInContent = getActorsFromNarrative(narrative)
    let newContent = narrative
    findActorsInContent.forEach(suggestedActor => {
        const existingActor = actors.find(actor => {
            return (
                actor.name.toLowerCase() ===
                (suggestedActor.name as string).toLowerCase()
            )
        })

        if (existingActor) {
            newContent = newContent.replace(
                suggestedActor.item,
                `<actor id="${existingActor.ref.split("/")[1]}"></actor>`
            )
        }
    })

    return newContent
}

export const convertActorsFromNarrativeToObjects = ({ items, actors = [] }) => {
    const newItems = items.map(item => {
        const actorsFromNarrative = getActorsFromNarrative(item.narrative)

        const actorRefs = []

        actorsFromNarrative.forEach((narrativeActor, index) => {
            if (narrativeActor.id) {
                actorRefs.push(`Actor/${narrativeActor.id}`)
            } else if (narrativeActor.name) {
                const existingActor = actors.find(actor => {
                    return (
                        actor.name.toLowerCase() ===
                        (narrativeActor.name as string).toLowerCase()
                    )
                })

                if (existingActor) {
                    actorRefs.push(existingActor.ref)
                } else {
                    const actorRef = `Actor/${
                        narrativeActor.id ?? `tmp-${crypto.randomUUID()}`
                    }`

                    actors.push({
                        ref: actorRef,
                        name: narrativeActor.name,
                        category: narrativeActor.category ?? "system",
                        icon: narrativeActor.icon ?? "fas-circle-user",
                        description:
                            narrativeActor.description ?? "fas-circle-user",
                    })

                    actorRefs.push(actorRef)
                }
            }
        })

        return {
            ...item,
            actorRefs,
            narrative: addActorsToNarrative({
                narrative: item.narrative,
                actors,
            }),
        }
    })

    return { items: newItems, actors }
}
